<template>
  <v-combobox
    ref="select"
    v-model="selectedItem"
    item-text="email"
    item-value="email"
    :items="items"
    :label="label"
    :menu-props="{contentClass: dataCy}"
    :multiple="multiple"
    :disabled="disabled"
    :rules="rules"
    :data-cy="dataCy"
    deletable-chips
    hide-selected
    small-chips
    filled
    @input="inputItem">
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item" />
      </template>

      <template v-else>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.email }}
            <v-chip
              v-if="data.item.isMain"
              class="chip"
              color="darkGray"
              text-color="white"
              small
              label />
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-combobox>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        required: true
      },
      items: {
        type: Array,
        required: true
      },
      dataCy: {
        type: String,
        default: ''
      },
      rules: {
        type: Array,
        default: () => ([])
      },
      disabled: {
        type: Boolean,
        default: false
      },
      empty: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      value: {
        type: String,
        default: ''
      },
      editMode: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        selectedItem: ''
      }
    },
    watch: {
      empty (val) {
        val && this.clearField()
      },
      editMode (val) {
        val && this.setEmailEditMode()
      }
    },
    mounted () {
      this.$refs.select.$el.getElementsByTagName('input')[0].setAttribute('autocomplete', 'disabled')
    },
    methods: {
      inputItem (item) {
        if(Array.isArray(item)){
          this.selectedItem = item.email ? item.map(i => i.email) : item
        } else if (item) {
          this.selectedItem = item.email ? [item.email] : [item]
        } else {
          this.selectedItem = ''
        }
        console.log(this.selectedItem)
        this.$emit('change', this.selectedItem)
      },
      clearField () {
        this.selectedItem = []
        this.$emit('change', this.selectedItem)
      },
      setEmailEditMode () {
        this.selectedItem = [this.value]
      }
    }
  }
</script>
